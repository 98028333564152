// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".W7XP2ayxM_DaXd3g7qur{position:relative}.f27mB9HNbl9hqMnMSeRR{position:absolute !important;right:15px}", "",{"version":3,"sources":["webpack://./src/client/components/wrappers/modals/ModalCard/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACF,sBACE,4BAAA,CACA,UAAA","sourcesContent":[".header\n  position: relative\n.close\n  position: absolute !important\n  right: 15px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "W7XP2ayxM_DaXd3g7qur",
	"close": "f27mB9HNbl9hqMnMSeRR"
};
export default ___CSS_LOADER_EXPORT___;
