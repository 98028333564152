// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Cj40Th3nKB6VP2ye6vKY{color:#ddd}", "",{"version":3,"sources":["webpack://./src/client/pages/Assets/pages/AssetRevenueBreakdown/components/ValueBox/components/Header/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,UAAA","sourcesContent":[".note\n  color: #ddd\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"note": "Cj40Th3nKB6VP2ye6vKY"
};
export default ___CSS_LOADER_EXPORT___;
