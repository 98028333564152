// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BPpi4F7i0_sMvgBV_jID>*{z-index:40 !important}", "",{"version":3,"sources":["webpack://./src/client/pages/Assets/pages/Asset/components/AssetDetails/components/GiveAccess/styles.module.sass"],"names":[],"mappings":"AAAA,wBACE,qBAAA","sourcesContent":[".searchNickname > *    \n  z-index: 40 !important"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchNickname": "BPpi4F7i0_sMvgBV_jID"
};
export default ___CSS_LOADER_EXPORT___;
