// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".e_GUt4_YVJ83R7Beb4fi{max-width:1024px;width:100%;margin-left:auto;margin-right:auto}.ka2wQPB0Zs384Rmbe5MM{background:#f7f4f4}.KKdRNpv4KBBr1xWVNGma{margin-bottom:0 !important}.JJv9Nj7DGVJsHiGpx2_A text{text-transform:capitalize}.JJv9Nj7DGVJsHiGpx2_A .recharts-tooltip-label{text-transform:capitalize}", "",{"version":3,"sources":["webpack://./src/client/pages/Assets/pages/AssetRevenueBreakdown/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,gBAAA,CACA,UAAA,CACA,gBAAA,CACA,iBAAA,CACF,sBACE,kBAAA,CACF,sBACE,0BAAA,CAEA,2BACE,yBAAA,CAEA,8CACE,yBAAA","sourcesContent":[".main\n  max-width: 1024px\n  width: 100%\n  margin-left: auto\n  margin-right: auto\n.container\n  background: #f7f4f4\n.regularTemplate\n  margin-bottom: 0 !important\n.bars\n  text\n    text-transform: capitalize\n  :global\n    .recharts-tooltip-label\n      text-transform: capitalize\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": "e_GUt4_YVJ83R7Beb4fi",
	"container": "ka2wQPB0Zs384Rmbe5MM",
	"regularTemplate": "KKdRNpv4KBBr1xWVNGma",
	"bars": "JJv9Nj7DGVJsHiGpx2_A"
};
export default ___CSS_LOADER_EXPORT___;
