// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CEnSlaBhovWggfZCXiPG{width:70%;flex-grow:0}.NTjiSvofYWd32Tn8WqCw{width:28%;flex-grow:0}", "",{"version":3,"sources":["webpack://./src/client/pages/Assets/pages/AssetRevenueBreakdown/components/Graphs/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,SAAA,CACA,WAAA,CACF,sBACE,SAAA,CACA,WAAA","sourcesContent":[".bars\n  width: 70%\n  flex-grow: 0\n.circle\n  width: 28%\n  flex-grow: 0\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bars": "CEnSlaBhovWggfZCXiPG",
	"circle": "NTjiSvofYWd32Tn8WqCw"
};
export default ___CSS_LOADER_EXPORT___;
