// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WsqUlMdeYobE9Fj_tnlD{height:100%;margin-top:-10px;text-align:center;overflow-y:auto}@media(max-width: 768px){.WsqUlMdeYobE9Fj_tnlD{height:106%;overflow:auto}}.WsqUlMdeYobE9Fj_tnlD img{width:100%;max-width:800px}@media(max-width: 768px){.WsqUlMdeYobE9Fj_tnlD img{width:100%;margin:0 auto !important}}", "",{"version":3,"sources":["webpack://./src/client/components/wrappers/modals/ModalPreviewImage/styles.module.sass","webpack://./src/client/assets/sass/base/_breakpoints.sass"],"names":[],"mappings":"AAEA,sBACE,WAAA,CACA,gBAAA,CACA,iBAAA,CACA,eAAA,CCKA,yBDTF,sBAMI,WAAA,CACA,aAAA,CAAA,CACJ,0BACE,UAAA,CACA,eAAA,CCDA,yBDDF,0BAII,UAAA,CACA,wBAAA,CAAA","sourcesContent":["@import \"@/client/assets/sass/base/breakpoints\"\n\n.imgContainer  \n  height: 100%  \n  margin-top: -10px\n  text-align: center\n  overflow-y: auto\n  @include mobile    \n    height: 106%\n    overflow: auto\n.imgContainer img\n  width: 100%\n  max-width: 800px \n  @include mobile\n    width: 100%\n    margin: 0 auto !important\n","$screen-xs: 440px\n$screen-sm: 576px\n$screen-md: 768px\n$screen-lg: 992px\n$screen-xl: 1200px\n\n@mixin small-mobile\n   @media (max-width: #{$screen-xs})\n    @content\n\n@mixin mobile\n  @media (max-width: #{$screen-md})\n    @content\n\n@mixin small-pc\n  @media (max-width: #{1048px})\n    @content\n\n@mixin sm   \n  @media (min-width: #{$screen-sm})\n    @content\n\n@mixin md\n   @media (min-width: #{$screen-md})\n    @content\n\n@mixin lg\n  @media (min-width: #{$screen-lg})\n    @content\n\n@mixin xl\n  @media (min-width: #{$screen-xl})\n    @content\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"imgContainer": "WsqUlMdeYobE9Fj_tnlD"
};
export default ___CSS_LOADER_EXPORT___;
