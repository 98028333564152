// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ufvxyBmz0hPpr8QcoiAw{border-radius:10px;border:1px solid #ddd;padding:15px 20px;background:#fff}", "",{"version":3,"sources":["webpack://./src/client/pages/Assets/pages/AssetRevenueBreakdown/components/Box/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CACA,qBAAA,CACA,iBAAA,CACA,eAAA","sourcesContent":[".box\n  border-radius: 10px\n  border: 1px solid #ddd\n  padding: 15px 20px\n  background: #fff"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": "ufvxyBmz0hPpr8QcoiAw"
};
export default ___CSS_LOADER_EXPORT___;
