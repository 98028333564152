// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Ln6cDU4NaQCV0PR6Db9p{margin-bottom:20px;margin-top:20px}.yoDdJigYOAVeGL9UWord{display:flex;align-items:center}", "",{"version":3,"sources":["webpack://./src/client/pages/Assets/pages/AssetRevenueBreakdown/components/layout/Header/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CACA,eAAA,CACF,sBACE,YAAA,CACA,kBAAA","sourcesContent":[".backButton\n  margin-bottom: 20px\n  margin-top: 20px\n.backLink\n  display: flex\n  align-items: center\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"backButton": "Ln6cDU4NaQCV0PR6Db9p",
	"backLink": "yoDdJigYOAVeGL9UWord"
};
export default ___CSS_LOADER_EXPORT___;
