// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wwLy3fRlMR4L7nrjFuWm{width:240px}.Ih2F21ur2dYxZnFS7xPR{font-size:40px;font-weight:bold}", "",{"version":3,"sources":["webpack://./src/client/pages/Assets/pages/AssetRevenueBreakdown/components/ValueBox/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACF,sBACE,cAAA,CACA,gBAAA","sourcesContent":[".valueBox\n  width: 240px\n.value\n  font-size: 40px\n  font-weight: bold"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"valueBox": "wwLy3fRlMR4L7nrjFuWm",
	"value": "Ih2F21ur2dYxZnFS7xPR"
};
export default ___CSS_LOADER_EXPORT___;
