// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fEYlzfNBByDLrQm698c7{border-collapse:separate;border-spacing:0 10px}.JGTkMByxB0HiwXXmCMdj{width:280px}.e7Z1jl_77ruzngShiId0{font-weight:bold;line-height:1.1}._AN1PlJGDCGJI9DjazBK{line-height:1.1}", "",{"version":3,"sources":["webpack://./src/client/pages/Assets/pages/AssetRevenueBreakdown/components/layout/Header/component/Details/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,wBAAA,CACA,qBAAA,CACF,sBACE,WAAA,CACF,sBACE,gBAAA,CACA,eAAA,CACF,sBACE,eAAA","sourcesContent":[".list\n  border-collapse: separate\n  border-spacing: 0 10px\n.details\n  width: 280px\n.label\n  font-weight: bold\n  line-height: 1.1\n.value\n  line-height: 1.1"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list": "fEYlzfNBByDLrQm698c7",
	"details": "JGTkMByxB0HiwXXmCMdj",
	"label": "e7Z1jl_77ruzngShiId0",
	"value": "_AN1PlJGDCGJI9DjazBK"
};
export default ___CSS_LOADER_EXPORT___;
