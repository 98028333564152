// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".KIck5o1zsJ9TgK4JvLZd{width:700px;display:flex;justify-content:space-between;flex-grow:0}.tzbm2rQagTZPRp6_2fsg{width:450px}.TGDat313tiiAFcStZw5A{width:300px}.Bd113_D7O_jMpSXXD84G{font-size:20px;color:#888686;font-weight:bold}", "",{"version":3,"sources":["webpack://./src/client/pages/Assets/pages/AssetRevenueBreakdown/components/layout/Header/component/ReferenceArticle/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,YAAA,CACA,6BAAA,CACA,WAAA,CACF,sBACE,WAAA,CACF,sBACE,WAAA,CACF,sBACE,cAAA,CACA,aAAA,CACA,gBAAA","sourcesContent":[".referenceArticle\n  width: 700px\n  display: flex\n  justify-content: space-between\n  flex-grow: 0\n.info\n  width: 450px\n.image\n  width: 300px\n.creator\n  font-size: 20px\n  color: #888686\n  font-weight: bold"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"referenceArticle": "KIck5o1zsJ9TgK4JvLZd",
	"info": "tzbm2rQagTZPRp6_2fsg",
	"image": "TGDat313tiiAFcStZw5A",
	"creator": "Bd113_D7O_jMpSXXD84G"
};
export default ___CSS_LOADER_EXPORT___;
