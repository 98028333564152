// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jB2n2q20AvCS3g0Cm_dE{font-weight:bold;color:#ddd}.uUs8X2jjEywWngvrA4Tu{font-weight:bold;text-align:right}", "",{"version":3,"sources":["webpack://./src/client/pages/Assets/pages/AssetRevenueBreakdown/components/RevenueHistory/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,gBAAA,CACA,UAAA,CACF,sBACE,gBAAA,CACA,gBAAA","sourcesContent":[".label\n  font-weight: bold\n  color: #ddd\n.total\n  font-weight: bold\n  text-align: right\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": "jB2n2q20AvCS3g0Cm_dE",
	"total": "uUs8X2jjEywWngvrA4Tu"
};
export default ___CSS_LOADER_EXPORT___;
